import React from "react";
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { makeStyles } from "@material-ui/core/styles";
import NonReactStatics from 'hoist-non-react-statics';
import hoistStatics from 'hoist-non-react-statics'
import AppsIcon from "@material-ui/icons/Apps";
import ReorderIcon from "@material-ui/icons/Reorder";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const Wrapper = styled.div`
top: 100px;
margin-top: 75px;`;

const useStyles = makeStyles(theme => ({
  toggleContainer: {
    margin: theme.spacing(2, 0)
  }
}));
const Component = ({ toggle, setToggle }) => {
  const handleAlignment = (event, newAlignment) => {
    setToggle(newAlignment);
  };

  const classes = useStyles();

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <div className={classes.toggleContainer}>
            <ToggleButtonGroup
              value={toggle}
              exclusive
              size="small"
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="list" aria-label="list">
                <ReorderIcon />
              </ToggleButton>
              <ToggleButton value="grid" aria-label="grid">
                <AppsIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
export default Component;