import styled from 'styled-components';

const ProjectContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
padding-left: -100px;
`;
const PortfolioContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: center;
flex-direction: column;
`;
export default { 
    Project: ProjectContainer, 
    Portfolio: PortfolioContainer 
};