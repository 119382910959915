import React from 'react';
import styled from 'styled-components';

const Component = () => {

    return (
        <div>
            <h1>GOOGLE PAGE</h1>
        </div>
    )
}
export default Component;