import React from 'react';
import styled from 'styled-components';

import Main from './main/index';
import Notebinge from './notebinge';
import Google from './google';
import About from './about';

const PageContainer = styled.div`
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 50px;
    margin-top: -50px;
    text-align: left;
    .p{
        {...${props => props.theme.text.description}}
    }
`;
const Title = styled.div`
    {...${props => props.theme.text.title}}
    padding-top: 80px;
    zoom: 1.2;
`;

const Component = props => {
    return (
        <React.Fragment>
            <PageContainer>
                    {props.children}
            </PageContainer>
        </React.Fragment>
    );
};
export default {
    Container: Component, 
    Title,
    Main,
    Notebinge,
    Google,
    About
};