import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Cards from './card';

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

const Component = () => {
    return (
        <Container>
            <Cards/>
        </Container>
    );
}
export default Component;