import React, { useState } from 'react';
import styled from 'styled-components';

import Projects from './projects'
import Container from "../styledContainer";
import Page from "../../page";
import Toggle from './projects/toggle';
import List from './projects/list';

const StyledHr = styled.hr`
  border: 0;
  clear:both;
  display:block;
  width: 100%;               
  background-color: #e0e0e0;
  height: 1px;
  margin: 30px 0px;
`;
const ContentWrapper = styled.div`
max-width: 50rem;  
`;
const StyledBio = styled.p`
padding-top: 10px;
`;
const Beep = styled.div`
margin-bottom: 50px;
text-align: center;
`;
const Boop = styled.div`
// width: 100%;
display: flex;
flex-direction: row unwrap;
justify-content: space-between;
align-self: flex-end;
`;
const Component = () => {
    const [toggle, setToggle] = useState('list');
    const StyledText = {
        // textDecorationLine: "underline",
        fontStyle: "italic",
        color: "#ff502f"
    };
    return (
        <div>
            <Container.Project>
                <ContentWrapper>
                    <Boop>
                        <Page.Title id={"projects"}>Projects</Page.Title>
                        <Toggle toggle={toggle} setToggle={setToggle} />
                    </Boop>
                    <StyledBio>During the absence of school work, I like to embark on new projects to explore &amp; learn the vast applications of code, designs and UX.
                I have a passion for writing <b style={StyledText}>clean code</b> and creating <b style={StyledText}>sleek, intuitive &amp; scalable interfaces</b> that improves the user experience.</StyledBio>
                    <StyledHr />
                    {toggle !== 'list' ? (<Projects />) : (<List />)}
                </ContentWrapper>
            </Container.Project>
            <Container.Project>
                <ContentWrapper>
                    <Page.Title id={"portfolio"}>portfolio</Page.Title>
                    <StyledBio>Upon recently discovering UX design, I'm fascinated in how engineers and designers have been analyzing and influencing human behavior.</StyledBio>
                    <StyledHr />
                    <Beep>
                        <h3>...under construction...</h3>
                        <h3><a href={"https://website-b029e.firebaseapp.com/"} style={StyledText}>CLICK HERE</a> to see the old layout of my design portfolio.</h3>
                        <iframe src="https://giphy.com/embed/pOKrXLf9N5g76" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
                    </Beep>
                </ContentWrapper>
            </Container.Project>
        </div>
    )
}
export default Component;