import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import ApolloClient from "apollo-boost";
// import { ApolloProvider } from "react-apollo";
// const client = new ApolloClient({
//     uri: "https://api.github.com/graphql"
// });
// const Data = () => {
//     const [error, setError] = useState(false);
//     const [projects, setProjects] = useState({});
//     useEffect(() => {
//         async function fetchData() {
//             const res = await fetch("https://api.github.com/graphql");
//             res
//                 .json()
//                 .then(res => setProjects(res))
//                 .catch(err => setError(err));
//         }
//         fetchData();
//     });
//     return (
//         <div>{JSON.stringify(projects)}</div>
//     );
// }
const Container = styled.div`
  display: grid;
  width: 90%;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  grid-auto-rows: 10rem;;
  grid-gap: 20px 20px;
  padding: 3rem 0;
  margin-top: -10px;
//   margin-left: -20px;
`;
const Background = styled.div`
    transform: translateZ(0);
    box-shadow: 0px 1.11px 5.16px rgba(0, 0, 0, 0.25);
    width: 331px;
    height: 140px;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFF;
    transition-duration: 0.3s;
    margin: 10px;
    display: flex;
    zoom: 1.2;
    display: flex;
    flex-wrap: wrap;

    &:hover, &:focus, &:active {
        transform: scale(1.1);
        transition-property: transform;
        vertical-align: middle;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }
`;

const CardWrapper = styled.div`
    display: flex;
    flex-flow: row unwrap;
    justify-content: space-between;
    align-items:center;
`;

const Image = styled.img`
    width: 60px;
    height: 60px;
    padding: 25px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;
`;

const Title = styled.div`
{...${props => props.theme.text.cardTitle}}
padding-bottom: 5px;
`;
const Sub = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12.6316px;
line-height: 15px;
letter-spacing: 0.02em;
padding-bottom: 10px;
`;
const Desc = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 9.47368px;
line-height: 11px;
letter-spacing: 0.02em;
padding-bottom: 10px;
`;
const Footer = styled.div`
display: flex;
flex-flow: row unwrap;
justify-content: space-between;
`;
const TypeWrapper = styled.div`
display: flex;
flex-flow: row unwrap;
justify-content: flex-start;
align-self: flex-end;
`;
const Circle = styled.div`
width: 10px;
height: 10px;
border-radius: 50%;
background: ${props => props.color};
`;
const Type = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 9.47368px;
line-height: 11px;
letter-spacing: 0.02em;
`;
const Link = styled.a`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 9px;
line-height: 9px;
letter-spacing: 0.02em;
text-decoration-line: underline;
color: #2F80ED;
padding-right: 10px;
`;
const Component = () => {
    const projects = [
        {
            id: 0,
            image: "https://image.flaticon.com/icons/svg/2038/2038018.svg",
            title: "moodi",
            sub: "Sentiment Analysis - a REST API",
            desc: "REST API made with node.js using AFINN-111 word list. Website coming soon...",
            color: "#F2C94C",
            type: "Javascript",
            link: "https://github.com/margaretgyou/moodi",
            place:"View on Github >"
        },
        {
            id: 1,
            image: "https://image.flaticon.com/icons/svg/1427/1427650.svg",
            title: "snake game",
            sub: "Remaking the OG snake game",
            desc: "Experimenting with p5.js library. Snake game with multiplayer & difficulty. Live demo coming soon...",
            color: "#F2C94C",
            type: "Javascript",
            link: "https://github.com/margaretgyou/Extended-Snake-Game",
            place:"View on Github >"
        },
        {
            id: 2,
            image: "https://image.flaticon.com/icons/svg/1040/1040993.svg",
            title: "full house",
            sub: "Platform to list & find roommates",
            desc: "Airbnb-like website to find roommates based on similarity in needs. Made with Angular CLI & Firestore(NoSQL DB)",
            color: "#2b7489",
            type: "Typescript",
            link: "https://myfullhouse-3d69f.firebaseapp.com/",
            place:"View website >"
        },
        {
            id: 3,
            image: "https://image.flaticon.com/icons/svg/148/148998.svg",
            title: "Keen App",
            sub: "Social media app: Founder & developer",
            desc: "React Native app, made with react hooks, Apollo, GraphQL, MongoDB & AWS. Project cannot be disclosed yet, IP protected.",
            color: "#F2C94C",
            type: "Javascript",
            // link: "https://keenapp.io",
            // place:"View website >"
        },
        {
            id: 4,
            image: "https://image.flaticon.com/icons/svg/1149/1149168.svg",
            title: "Keen Website",
            sub: "Website of Keen App",
            desc: "Made & designed by Margaret. Made with React.",
            color: "#F2C94C",
            type: "Javascript",
            link: "https://keenapp.io",
            place:"View website >"
        }
    ];
    return (
        // <ApolloProvider client={client}>
            <Container>
                {projects.map(project => (
                    <Background key={project.id}>
                        <CardWrapper >
                            <Image src={project.image} />
                            <ContentWrapper>
                                <Title>{project.title}</Title>
                                <Sub>{project.sub}</Sub>
                                <Desc>{project.desc}</Desc>
                                <Footer>
                                    <TypeWrapper>
                                        <Circle color={project.color} />
                                        <Type>{project.type}</Type>
                                    </TypeWrapper>
                                    <Link target="_blank" href={project.link}>{project.place}</Link>
                                </Footer>
                            </ContentWrapper>
                        </CardWrapper>
                    </Background>
                ))}
            </Container>
        // </ApolloProvider>
    );
}
export default Component;