import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 2rem;
`;
const TitleWrapper = styled.div`
{...${props => props.theme.text.title}}
width: 100%;
font-size: 20px;
text-transform: capitalize;
display: flex:
flex-direction: row unwrap;
padding: 20px 0px;
`;
const StyledHr = styled.hr`
  border: 0;
  clear:both;
  display:block;
  width: 100%;               
  background-color: black;
  height: 1px;
  margin: 30px 0px;
`;
const P = styled.p`
line-height: 25px;
color: #474747;
padding-bottom: 10px;
`;
const Component = () => {
    const StyledText = {
        textDecorationLine: "underline",
        color: "#ff502f"
    };
    return (
        <Container>
            <TitleWrapper>
                <div>KEEN</div>
                {/* <StyledHr /> */}
            </TitleWrapper>
            <P><u style={StyledText}>KEEN</u> is an upcoming dating app that revolutionizes the way we share and connect online. 
            The current prototype is made with a <u style={StyledText}>MERNG stack</u>. 
            It is quite overwhelming being a founder of a startup, but it is truly a rewarding learning experience; I am able to adopt the roles of a PM, designer and engineer! 
            It really has given me a holistic view on product development. </P>
            <P>Currently mentored by the <a href={"https://www.mcgill.ca/dobson/entrepreneurship-programs/mcgill-lean-startup-program"} style={StyledText}>McGill Lean Startup Program (Incubator)</a>. 
            View the <a href={"https://keenapp.io"} style={StyledText}>Keen website</a>.
            </P>
            <TitleWrapper>
                <div>Full House</div>
                {/* <StyledHr /> */}
            </TitleWrapper>
            <P><u style={StyledText}>Full House</u> is the final project made for COMP 307 - Web Development. It's a platform to find roommates with a similar concept to Airbnb. Roommates are presented to users based on relevance to their habits and needs. Users create an account, find roommates, find apartments, and/or post their own listings.</P>
            <P>It's made with Angular 6, hosted on Firebase, and used Firestore - the noSQL database. It's not of the most optimal structure and implementation as it was made in three days; improvements are to be made.
                View the <a href={"https://github.com/margaretgyou/FullHouse"} style={StyledText}>source on Github</a> or <a href={"https://myfullhouse-3d69f.firebaseapp.com/"} style={StyledText}>visit the site</a>.</P>
            <TitleWrapper>
                <div>Moodi</div>
                {/* <StyledHr /> */}
            </TitleWrapper>
            <P><u style={StyledText}>Moodi</u> is a REST API for sentiment analysis, using the AFINN-111 word list.
            My goal is to build a 'bullet journal' web application that uses the API for mood tracking on the user's diary entries.
            I'm currently still playing around with it.
            View the <a href={"https://github.com/margaretgyou/moodi"} style={StyledText}>source on Github</a>. Website coming soon.
            </P>
            <TitleWrapper>
                <div>Algorithm Visualization</div>
                {/* <StyledHr /> */}
            </TitleWrapper>
            <P><u style={StyledText}>Algorithm Visualization</u>, as per the title, is the visualization of the common sorting algorithms. It sorts lines of equal length by angles, and present the runtime and pseudo code.
                This project was a great refresher and would aid COMP 250.
                I was inspired by this <a href={"https://www.youtube.com/watch?v=kPRA0W1kECg"} style={StyledText}>video</a>.
                I will update it with even more algorithms over time. View the <a href={"https://github.com/margaretgyou/FullHouse"} style={StyledText}>source on Github</a>. Demo coming soon.</P>
            <TitleWrapper>
                <div>Extended Snake Game</div>
                {/* <StyledHr /> */}
            </TitleWrapper>
            <P><u style={StyledText}>Extended Snake Game</u> is a recreation of the OG snake game.
            It has three levels of difficulty(speed) and the option for dual play.
            It is a quick project to practice coding and to explore the <u style={StyledText}>p5.js</u> library.
            View the <a href={"https://github.com/margaretgyou/Extended-Snake-Game"} style={StyledText}>source on Github</a>. Demo coming soon.</P>


        </Container>
    )
}
export default Component;